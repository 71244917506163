import styled from 'styled-components';
import { GridCol, GridRow } from '@naf/grid';
import { Button } from '@naf/button';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';

const Wrapper = styled.div``;

// Per i dag fungerer ikke designet til CampaignBlock hvis den ikke ligger i en relative container
const RelativeGridRow = styled(GridRow)`
  position: relative;
`;

const HeaderCol = styled(GridCol)`
  background: ${nafColor.signature.white};
  padding: ${spacing.space40};
  padding-left: 0;
  margin: ${spacing.space48} 0;

  h1 {
    margin-top: 0;
  }

  @media (max-width: ${breakpoints.m}) {
    margin: 0;
    padding: 0;
  }
`;

const ButtonContainer = styled.div`
  margin-top: ${spacing.space24};

  span {
    text-align: center;
  }

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space8};
  }
`;

const StyledButton = styled(Button)`
  margin-top: ${spacing.space8};

  &:not(:last-child) {
    margin-right: ${spacing.space24};
  }
`;

const MainImage = styled.div`
  position: absolute;
  right: 0;
  z-index: -1;
  height: 100%;
  max-width: 70%;
  display: flex;

  img {
    object-fit: cover;
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: ${breakpoints.m}) {
    position: static;
    grid-column: auto / span 12;
    max-width: 100%;
    order: -1;
  }
`;

const IngressCol = styled(GridCol)``;

const LoginCol = styled(GridCol)`
  grid-column-start: 9;
  margin-bottom: ${spacing.space48};
  height: 0;
  overflow: visible;

  @media (max-width: ${breakpoints.m}) {
    grid-column-start: auto;
    margin-top: -${spacing.space20};
    height: unset;

    h2 {
      margin-top: 0;
    }
  }

  h2 {
    margin-bottom: ${spacing.space8};
  }

  span {
    white-space: nowrap;
  }

  img {
    margin-top: ${spacing.space24};
  }
`;

const SpacedGridRow = styled(GridRow)`
  margin-bottom: ${spacing.space48};
`;

const Body = styled.div`
  margin-bottom: 54px;

  & > div:first-child {
    & > :first-child {
      margin-top: 0;
    }

    & > :last-child {
      margin-bottom: 0;
    }
  }

  p {
    margin: 25px 0;
    max-width: 576px;

    a:hover {
      color: ${nafColor.primary.park};
    }
  }

  h2 {
    font-size: 2rem;
    line-height: 1.25;
    font-weight: 900;
    margin-top: 80px;
    letter-spacing: 0;
    margin-bottom: 0;
    font-style: normal;
  }

  h2 + p,
  h2 + ul,
  h2 + table,
  h2 + ol {
    margin-top: 29px;
  }
`;

const ColoredBox = styled.div`
  position: relative;
  padding-top: 80px;
  padding-bottom: 103px;

  &::before {
    content: '';
    background-color: ${nafColor.primary.dew};
    margin-left: -50vw;
    position: absolute;
    width: 150vw;
    height: 100%;
    z-index: -1;
    top: 0;

    @media (max-width: ${breakpoints.m}) {
      width: 100vw;
      margin-left: -25px;
      padding-left: 25px;
      margin-right: -25px;
      padding-right: 25px;
    }
  }
`;

const QuoteCol = styled(GridCol)`
  display: flex;
  justify-content: center;

  @media (max-width: ${breakpoints.s}) {
    justify-content: flex-start;
  }
`;

const ProductPrice = styled.div`
  h3,
  p {
    margin: 0;
  }

  h3 {
    margin-bottom: ${spacing.space8};
  }
`;

const ReadMoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.space48};
  margin-bottom: ${spacing.space40};

  h2 {
    margin: 0;
  }
`;

export default {
  Wrapper,
  RelativeGridRow,
  HeaderCol,
  ButtonContainer,
  StyledButton,
  MainImage,
  IngressCol,
  LoginCol,
  SpacedGridRow,
  Body,
  ColoredBox,
  QuoteCol,
  ProductPrice,
  ReadMoreWrapper,
};
